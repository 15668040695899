export * from 'cf-core/src/images'
export const Hero404 =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582791158/general/404Hero.png'
export const appStore =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/app-store.png'
export const cftitle =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/cftitle.png'
export const googlePlay =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/google-play.png'
export const user =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/ebisu/user.png'
export const sharedBg =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1582514489/general/marblebgdefault.jpg'
export const orderStatusProgress =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554602154/general/OrderStatusProgress.png'
export const orderStatusMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/v1554666730/general/OrderStatusMeter.png'
export const promoHeader =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/Shaolin/promoHeader.png'
export const restaurant =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1562129124/Shaolin/restaurant.jpg'
export const yelp =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/yelp_white.png'
export const facebook =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869832/general/facebook_white.png'
export const zomato =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/zomato_white.png'
export const instagram =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto/v1601869833/general/instagram_white.png'
export const contactMobilebg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/contactMobilebg.jpg'
export const rewardbg =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardbg.jpg'
export const downloadButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/downloadButton.png'
export const flowerTL =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerTL.png'
export const flowerBR =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/flowerBR.png'
export const redeemButton =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/redeemButton.png'
export const pointsMeter =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/pointsMeter.png'
export const rewardArrowLeft =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowLeft.png'
export const rewardArrowRight =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardArrowRight.png'
export const rewardDefault =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1576892025/halusushi/rewardDefault.png'
export const viewMenu =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1582589981/Kaido/viewMenu.png'
export const cflogo =
  'https://res.cloudinary.com/codefusiontech/image/upload/f_auto,q_auto:best/v1591377852/general/cflogo_black.png'
export const about =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto/v1616397007/about_1_hcdmyl.jpg'
export const contactbg =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto/v1616397217/Mask_Group_1_obbuga.jpg'
export const hero =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/f_auto,q_auto/v1616456769/cheamsushi/herobg.jpg'
export const heroText =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/v1616456695/cheamsushi/heroText.png'
export const logo =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto/v1616396516/logo_rbdmim.svg'
export const locationMap =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto/v1616397173/locationMap_1_wmthr1.jpg'
export const mobileAbout =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto/v1616397643/aboutMobile_1_g6j7c2.jpg'
export const mobileContactbg =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1616458084/cheamsushi/contactbg.jpg'
export const mobileHero =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto:best/v1616457697/cheamsushi/herobgMobile.jpg'
export const mobileMap =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/v1616457946/cheamsushi/mobileMap.jpg'
export const mobileHeroText =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/v1616457697/cheamsushi/mobileHeroText.png'
export const mobilePromotion =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto:best/v1616397564/promotionsMobile_1_kz2zoc.jpg'
export const orderPickupButton =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/v1616456695/cheamsushi/orderPickupButton.png'
export const promotions =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/v1616457476/cheamsushi/promotions.jpg'
export const foodGallery =
  'https://res.cloudinary.com/ordernow/image/upload/f_auto,q_auto/v1616456695/cheamsushi/foodGallery.jpg'
export const mobileGallery =
  'https://res.cloudinary.com/dier/image/upload/f_auto,q_auto/v1616397700/foodGallery_2_1_l4h9cn.jpg'
